import request from '../../utils/axios';


//获取活动时间
export function getJfConfigurationValue(data) {
    return request({
        url: '/bpis/common/getJfConfigurationValue',
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

// 获取讲师列表数据
export function getTeacherList(data) {
    return request({
        url: '/bpis/common/brandLineAndImageList',
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

// 获取非农倒计时
export function getTime(data) {
    return request({
        url: '/bpis/common/getJfConfigurationValue',
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export function getSYLApi(data) {
    return request({
        // url: baseUrl + '/v4/USUP001',
        url: '/bpis/common/pmCmsSystemRelay?path=' + '/v1/USUP006',
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json'
        },
    })
}
// 美国非农就业
export function getFNApi(data) {
    return request({
        // url: baseUrl + '/v4/NF001',
        url: '/bpis/common/pmCmsSystemRelay?path=' + '/v1/NF006',
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json'
        },
    })
}
